import Vue from 'vue'

export default {
  SET (state, payload) {
    state.vehicleMaintenance = Object.assign({}, state.vehicleMaintenance, payload) //precisa ser assim para manter a reatividade
  },
  SET_VEHICLE_MAINTENANCES_GRID (state, payload) {
    state.vehicleMaintenancesGrid = payload
  },
  UPDATE (state, payload) {
    const idx = state.vehicleMaintenances.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.vehicleMaintenances, idx, payload)

    const idx1 = state.vehicleMaintenancesGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.vehicleMaintenancesGrid.data, idx1, payload)
  },
  STORE (state, payload) {
    state.vehicleMaintenances.push(payload) //precisa ser assim para manter a reatividade
    state.vehicleMaintenances.sort((a, b) => { return new Date(a.date_of_exchange) - new Date(b.date_of_exchange) }) //ORDENA POR DATA

    state.vehicleMaintenancesGrid.data.push(payload)
    state.vehicleMaintenancesGrid.data.sort((a, b) => { return new Date(a.date_of_exchange) - new Date(b.date_of_exchange) }) //ORDENA POR DATA
    state.vehicleMaintenancesGrid.total = state.vehicleMaintenancesGrid.total + 1
  },

  DELETE (state, payload) {
    const idx1 = state.vehicleMaintenances.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.vehicleMaintenances.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.vehicleMaintenancesGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.vehicleMaintenancesGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.vehicleMaintenancesGrid.total = state.vehicleMaintenancesGrid.total - 1
    }
  }

}
