import state from './moduleVehicleMaintenanceState.js'
import mutations from './moduleVehicleMaintenanceMutations.js'
import actions from './moduleVehicleMaintenanceActions.js'
import getters from './moduleVehicleMaintenanceGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

