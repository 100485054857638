export default {
  get (state) {
    const vehicleMaintenance = JSON.parse(JSON.stringify(state.vehicleMaintenance))
    vehicleMaintenance.date_of_exchange = vehicleMaintenance.date_of_exchange && vehicleMaintenance.date_of_exchange.slice(0, 10)
    vehicleMaintenance.exchange_expiration = vehicleMaintenance.exchange_expiration && vehicleMaintenance.exchange_expiration.slice(0, 10)

    vehicleMaintenance.transactions.sort((a, b) => { return new Date(a.expiration_date) - new Date(b.expiration_date) }) //ORDENA POR DATA
    vehicleMaintenance.transactions.map(a => {
      a.payday = a.payday && a.payday.slice(0, 10)
      if (a.situation === 'PAID') a.situation = true
      else a.situation = false
    })
    return vehicleMaintenance
  }
}
