var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("permission-password", {
            staticStyle: { "z-index": "9999999999" },
            attrs: {
              show: _vm.popupPermissionPassword,
              title: "ALERTA DE BAIXA RETROATIVA",
              icon: "warning",
              actionButtonText: "Prosseguir",
            },
            on: {
              action: function ($event) {
                return _vm.save(true)
              },
              cancel: function ($event) {
                _vm.popupPermissionPassword = false
              },
            },
          }),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: { label: "Nota Fiscal" },
                      model: {
                        value: _vm.vehicleMaintenance.nota_fiscal,
                        callback: function ($$v) {
                          _vm.$set(_vm.vehicleMaintenance, "nota_fiscal", $$v)
                        },
                        expression: "vehicleMaintenance.nota_fiscal",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "date",
                        label: "Realização",
                        "data-vv-as": "Realização",
                        name: "date_of_exchange",
                      },
                      model: {
                        value: _vm.vehicleMaintenance.date_of_exchange,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.vehicleMaintenance,
                            "date_of_exchange",
                            $$v
                          )
                        },
                        expression: "vehicleMaintenance.date_of_exchange",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("date_of_exchange"),
                            expression: "errors.has('date_of_exchange')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("date_of_exchange")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: { type: "date", label: "Validade" },
                      model: {
                        value: _vm.vehicleMaintenance.exchange_expiration,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.vehicleMaintenance,
                            "exchange_expiration",
                            $$v
                          )
                        },
                        expression: "vehicleMaintenance.exchange_expiration",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "min_value:0",
                          expression: "'min_value:0'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "number",
                        min: "0",
                        label: "KM Atual",
                        "data-vv-as": "KM Atual",
                        name: "current_mileage",
                      },
                      model: {
                        value: _vm.vehicleMaintenance.current_mileage,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.vehicleMaintenance,
                            "current_mileage",
                            $$v
                          )
                        },
                        expression: "vehicleMaintenance.current_mileage",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("current_mileage"),
                            expression: "errors.has('current_mileage')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Mínimo 0")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "min_value:0",
                          expression: "'min_value:0'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "number",
                        label: "Retornar com KM",
                        "data-vv-as": "Retornar com KM",
                        name: "next_exchange",
                      },
                      model: {
                        value: _vm.vehicleMaintenance.next_exchange,
                        callback: function ($$v) {
                          _vm.$set(_vm.vehicleMaintenance, "next_exchange", $$v)
                        },
                        expression: "vehicleMaintenance.next_exchange",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("next_exchange"),
                            expression: "errors.has('next_exchange')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Mínimo 0")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Responsável"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      attrs: {
                        reduce: (option) => option.value,
                        "data-vv-as": "Responsável",
                        name: "employee_id",
                        clearable: true,
                        options: _vm.employees,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Desculpe, nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.vehicleMaintenance.employee_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.vehicleMaintenance, "employee_id", $$v)
                        },
                        expression: "vehicleMaintenance.employee_id",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("employee_id"),
                            expression: "errors.has('employee_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("employee_id")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Fornecedor"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      attrs: {
                        clearable: true,
                        filterable: false,
                        reduce: (option) => option.value,
                        options: _vm.providerOptions,
                        "data-vv-as": "Fornecedor",
                        name: "provider_id",
                        placeholder: "Digite CPF/CNPJ ou nome do fornecedor...",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        search: _vm.debouncedGetSearchProvider,
                        "option:selected": _vm.selectedProvider,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.vehicleMaintenance.provider_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.vehicleMaintenance, "provider_id", $$v)
                        },
                        expression: "vehicleMaintenance.provider_id",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("provider_id"),
                            expression: "errors.has('provider_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("provider_id")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Total"),
                    ]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input normal hasValue",
                      attrs: {
                        currency: { prefix: "R$ " },
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false,
                      },
                      model: {
                        value: _vm.vehicleMaintenance.value_total,
                        callback: function ($$v) {
                          _vm.$set(_vm.vehicleMaintenance, "value_total", $$v)
                        },
                        expression: "vehicleMaintenance.value_total",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "InfoIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Serviços Realizados"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-2" }),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c(
              "div",
              { staticClass: "w-full" },
              _vm._l(_vm.vehicleMaintenance.services, function (service, i) {
                return _c("div", { key: i, staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/6 w-full mt-0" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: "required|min_value:1",
                            expression: "'required|min_value:1'",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "w-full mt-0",
                        attrs: {
                          type: "number",
                          min: "1",
                          label: "Qtd.",
                          "data-vv-as": "Qtd.",
                          name: "quantity_" + i,
                        },
                        model: {
                          value: service.quantity,
                          callback: function ($$v) {
                            _vm.$set(service, "quantity", $$v)
                          },
                          expression: "service.quantity",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("quantity_" + i),
                              expression: "errors.has('quantity_'+i)",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Mínimo 1")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Total"),
                      ]),
                      _c("currency-input", {
                        staticClass:
                          "w-full vs-inputx vs-input--input normal hasValue",
                        attrs: {
                          currency: { prefix: "R$ " },
                          valueAsInteger: false,
                          distractionFree: false,
                          precision: 2,
                          autoDecimalMode: true,
                          valueRange: { min: 0 },
                          allowNegative: false,
                        },
                        model: {
                          value: service.value,
                          callback: function ($$v) {
                            _vm.$set(service, "value", $$v)
                          },
                          expression: "service.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Serviço"),
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: "required",
                            expression: "'required'",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "vue_select_drop_size_160",
                        attrs: {
                          reduce: (option) => option.value,
                          "data-vv-as": "Serviço",
                          name: "service_" + i,
                          clearable: false,
                          options: _vm.serviceMaintenances,
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "no-options",
                              fn: function ({}) {
                                return [
                                  _vm._v(
                                    "\n                Desculpe, nenhum resultado encontrado.\n              "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: service.service,
                          callback: function ($$v) {
                            _vm.$set(service, "service", $$v)
                          },
                          expression: "service.service",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("service_" + i),
                              expression: "errors.has('service_'+i)",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Obrigatório")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                    [
                      _c(
                        "vx-tooltip",
                        {
                          staticClass: "h-5 w-5 mt-5",
                          attrs: {
                            text: i ? "Remover Serviço" : "Adicionar Serviço",
                            position: "top",
                          },
                        },
                        [
                          i === 0
                            ? _c("feather-icon", {
                                staticClass:
                                  "cursor-pointer hover:text-primary",
                                attrs: {
                                  icon: "PlusCircleIcon",
                                  svgClasses: "w-10 h-10",
                                },
                                on: { click: _vm.addServiceField },
                              })
                            : _c("feather-icon", {
                                staticClass:
                                  "text-danger cursor-pointer hover:text-primary",
                                attrs: {
                                  icon: "MinusCircleIcon",
                                  svgClasses: "w-10 h-10",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeServiceField(i)
                                  },
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Descrição dos serviços" },
                  model: {
                    value: _vm.vehicleMaintenance.service_description,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.vehicleMaintenance,
                        "service_description",
                        $$v
                      )
                    },
                    expression: "vehicleMaintenance.service_description",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "DollarSignIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Pagamento"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-2" }),
          _c("div", { staticClass: "vx-row" }),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "date",
                    label: "1º Vencimento",
                    "data-vv-as": "1º Vencimento",
                    "data-vv-scope": "debit",
                    name: "expirationDateFirstParcel",
                  },
                  model: {
                    value: _vm.debit.expirationDateFirstParcel,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "expirationDateFirstParcel", $$v)
                    },
                    expression: "debit.expirationDateFirstParcel",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has(
                          "debit.expirationDateFirstParcel"
                        ),
                        expression:
                          "errors.has('debit.expirationDateFirstParcel')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
                !_vm.isSameOrAfterToday(_vm.debit.expirationDateFirstParcel)
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("A primeira parcela já está vencida."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Periodicidade"),
                ]),
                _c("v-select", {
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: [
                      { label: "Mensal", value: "monthly" },
                      { label: "Quinzenal", value: "biweekly" },
                      { label: "Semanal", value: "weekly" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.debit.frequency,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "frequency", $$v)
                    },
                    expression: "debit.frequency",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Tipo de Pagamento"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.typePayments,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  on: { input: _vm.filterAccounts },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.debit.type_payment_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "type_payment_id", $$v)
                    },
                    expression: "debit.type_payment_id",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("debit.type_payment_id"),
                        expression: "errors.has('debit.type_payment_id')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v("Este campo é obrigatório.")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Conta"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    disabled: !_vm.debit.type_payment_id,
                    reduce: (option) => option.value,
                    clearable: false,
                    options: _vm.accountsFilteredOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.debit.account_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "account_id", $$v)
                    },
                    expression: "debit.account_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Plano de contas"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_180",
                  attrs: {
                    reduce: (option) => option.value,
                    options: _vm.accountPlans,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n            Desculpe, nenhum resultado encontrado.\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.debit.account_plan_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "account_plan_id", $$v)
                    },
                    expression: "debit.account_plan_id",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Nº de Parcelas"),
                ]),
                _c("vs-input-number", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:1",
                      expression: "'required|min_value:1'",
                    },
                  ],
                  staticClass: "mt-2",
                  attrs: {
                    min: "1",
                    "data-vv-as": "Parcelas",
                    "data-vv-scope": "debit",
                    name: "parcelsNumber",
                  },
                  model: {
                    value: _vm.debit.parcelsNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "parcelsNumber", $$v)
                    },
                    expression: "debit.parcelsNumber",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("debit.parcelsNumber"),
                        expression: "errors.has('debit.parcelsNumber')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("debit.parcelsNumber")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/5 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "currency",
                      rawName: "v-currency",
                      value: _vm.currencyConfig,
                      expression: "currencyConfig",
                    },
                  ],
                  ref: "firstParcelValue",
                  staticClass: "w-full mt-0",
                  attrs: {
                    label: "Valor 1ª Parcela",
                    disabled: _vm.debit.parcelsNumber < 2,
                  },
                  model: {
                    value: _vm.debit.firstParcelValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.debit, "firstParcelValue", $$v)
                    },
                    expression: "debit.firstParcelValue",
                  },
                }),
                _vm.errorfirstParcelValue
                  ? _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v("Este valor é maior que o total."),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-0" }),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-0" }),
            _c("div", { staticClass: "vx-col md:w-1/5 w-full mt-6" }, [
              _c(
                "div",
                { staticClass: "mt-0 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "w-full mt-1",
                      attrs: {
                        disabled: _vm.disableGenerateParcels,
                        type: "border",
                        color: "warning",
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                      },
                      on: { click: _vm.generateParcels },
                    },
                    [_vm._v("Gerar Parcelas")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "vs-divider",
            { attrs: { position: "left" } },
            [
              _c("feather-icon", {
                staticClass: "mr-0",
                attrs: { icon: "DollarSignIcon", svgClasses: "w-4 h-4" },
              }),
              _c("span", { staticClass: "mb-4" }, [_vm._v("Parcelas")]),
            ],
            1
          ),
          _vm.debit.transactions.length
            ? _c(
                "vs-table",
                {
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.debit.transactions, hoverFlat: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return _vm._l(data, function (parcel, i) {
                            return _c(
                              "vs-tr",
                              {
                                key: i,
                                staticStyle: { opacity: "1 !important" },
                              },
                              [
                                _c("vs-td", [
                                  _vm._v(" " + _vm._s(i + 1) + " "),
                                ]),
                                _c(
                                  "vs-td",
                                  [
                                    _c("currency-input", {
                                      staticClass:
                                        "w-32 vs-inputx vs-input--input normal hasValue",
                                      attrs: {
                                        currency: { prefix: "R$ " },
                                        valueAsInteger: false,
                                        distractionFree: false,
                                        precision: 2,
                                        autoDecimalMode: true,
                                        valueRange: { min: 0 },
                                        allowNegative: false,
                                      },
                                      on: {
                                        input: function ($event) {
                                          parcel.situation
                                            ? (parcel.value_pay =
                                                parcel.value_parcel)
                                            : (parcel.value_pay = 0)
                                        },
                                      },
                                      model: {
                                        value: parcel.value_parcel,
                                        callback: function ($$v) {
                                          _vm.$set(parcel, "value_parcel", $$v)
                                        },
                                        expression: "parcel.value_parcel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-td",
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate.initial",
                                          value: { required: true },
                                          expression: "{ required: true }",
                                          modifiers: { initial: true },
                                        },
                                      ],
                                      staticClass: "w-48",
                                      attrs: {
                                        type: "date",
                                        "data-vv-as": "Vencimento",
                                        "data-vv-scope": "debit",
                                        name: "expiration_date" + i,
                                      },
                                      model: {
                                        value: parcel.expiration_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            parcel,
                                            "expiration_date",
                                            $$v
                                          )
                                        },
                                        expression: "parcel.expiration_date",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has(
                                              "debit.expiration_date" + i
                                            ),
                                            expression:
                                              "errors.has('debit.expiration_date'+i)",
                                          },
                                        ],
                                        staticClass: "text-danger text-sm",
                                      },
                                      [_vm._v("Campo obrigatório")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-td",
                                  [
                                    _c("v-select", {
                                      staticClass:
                                        "vue_select_drop_size_100 w-48",
                                      attrs: {
                                        clearable: false,
                                        reduce: (option) => option.value,
                                        options: _vm.typePayments,
                                        placeholder: "Selecione",
                                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.filterAccountsParcels(i)
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "no-options",
                                            fn: function ({}) {
                                              return [
                                                _vm._v(
                                                  "\n                Nenhum resultado encontrado.\n              "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: parcel.type_payment_id,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            parcel,
                                            "type_payment_id",
                                            $$v
                                          )
                                        },
                                        expression: "parcel.type_payment_id",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-td",
                                  [
                                    _c("v-select", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate.initial",
                                          value: {
                                            required: !parcel.account_id,
                                          },
                                          expression:
                                            "{ required: !parcel.account_id }",
                                          modifiers: { initial: true },
                                        },
                                      ],
                                      staticClass:
                                        "vue_select_drop_size_100 w-48",
                                      attrs: {
                                        clearable: false,
                                        disabled: !parcel.type_payment_id,
                                        reduce: (option) => option.value,
                                        options: parcel.accountsFilteredOptions,
                                        placeholder: "Selecione",
                                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                        "data-vv-as": "Conta",
                                        "data-vv-scope": "debit",
                                        name: "account_id" + i,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "no-options",
                                            fn: function ({}) {
                                              return [
                                                _vm._v(
                                                  "\n                Nenhum resultado encontrado.\n              "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: parcel.account_id,
                                        callback: function ($$v) {
                                          _vm.$set(parcel, "account_id", $$v)
                                        },
                                        expression: "parcel.account_id",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has(
                                              "debit.account_id" + i
                                            ),
                                            expression:
                                              "errors.has('debit.account_id'+i)",
                                          },
                                        ],
                                        staticClass: "text-danger text-sm",
                                      },
                                      [_vm._v("Campo obrigatório")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("vs-td", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-wrap items-center justify-center",
                                    },
                                    [
                                      _c(
                                        "vs-switch",
                                        {
                                          staticClass: "w-28 mt-2",
                                          attrs: {
                                            "icon-pack": "feather",
                                            "vs-icon-on": "icon-check-circle",
                                            "vs-icon-off": "icon-slash",
                                          },
                                          on: {
                                            input: function ($event) {
                                              parcel.situation
                                                ? (parcel.value_pay =
                                                    parcel.value_parcel)
                                                : (parcel.value_pay = 0)
                                              parcel.situation
                                                ? (parcel.payday = _vm.today)
                                                : (parcel.payday = "")
                                              parcel.situation
                                                ? ""
                                                : (parcel.futureDate = false)
                                              _vm.checkPayday(parcel)
                                            },
                                          },
                                          model: {
                                            value: parcel.situation,
                                            callback: function ($$v) {
                                              _vm.$set(parcel, "situation", $$v)
                                            },
                                            expression: "parcel.situation",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "off" },
                                              slot: "off",
                                            },
                                            [_vm._v("ABERTA")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "on" },
                                              slot: "on",
                                            },
                                            [_vm._v("QUITADA")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "vs-td",
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate.initial",
                                          value: { required: parcel.situation },
                                          expression:
                                            "{ required: parcel.situation }",
                                          modifiers: { initial: true },
                                        },
                                      ],
                                      staticClass: "w-48",
                                      attrs: {
                                        type: "date",
                                        disabled: !parcel.situation,
                                        "data-vv-as": "Pago em",
                                        "data-vv-scope": "debit",
                                        name: "payday" + i,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.checkPayday(parcel)
                                        },
                                      },
                                      model: {
                                        value: parcel.payday,
                                        callback: function ($$v) {
                                          _vm.$set(parcel, "payday", $$v)
                                        },
                                        expression: "parcel.payday",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has(
                                              "debit.payday" + i
                                            ),
                                            expression:
                                              "errors.has('debit.payday'+i)",
                                          },
                                        ],
                                        staticClass: "text-danger text-sm",
                                      },
                                      [_vm._v("Campo obrigatório")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: parcel.futureDate,
                                            expression: "parcel.futureDate",
                                          },
                                        ],
                                        staticClass: "text-danger text-sm",
                                      },
                                      [_vm._v("Data futura não permitida")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        },
                      },
                    ],
                    null,
                    false,
                    4233129136
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("#")]),
                      _c("vs-th", [_vm._v("Valor")]),
                      _c("vs-th", [_vm._v("Vencimento")]),
                      _c("vs-th", [_vm._v("Forma Pagamento")]),
                      _c("vs-th", [_vm._v("Conta")]),
                      _c("vs-th", [_vm._v("Situação")]),
                      _c("vs-th", [_vm._v("Pago Em")]),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }